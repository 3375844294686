const isWindow = typeof window !== 'undefined' && window

import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { graphql } from 'gatsby'
import { shuffle, sortBy } from 'lodash'

// Components
import Article from '../components/Article'
import SEO from '../components/seo'

// Config
import FILTERS from '../filters';

// Styles
import * as style from './index.module.css'

const IndexPage = ({
	comments,
	data,
	filters,
	isBraveBrowser,
	isFilmComment,
	likes,
	setComment,
	setLike,
	supportsPush,
}) => {
	const { edges } = data.allMarkdownRemark

	const [scrollIndex, setScrollIndex] = useState(null)

	useEffect(() => {
		const scrollToNextEntry = e => {
			if (e.keyCode === 32 && isFilmComment === null) {
				e.preventDefault()
				if (
					scrollIndex === edges?.length - 1
					|| scrollIndex === null
				) {
					setScrollIndex(0)
				} else {
					setScrollIndex(scrollIndex + 1)
				}
			}
		}
		window.addEventListener('keydown', scrollToNextEntry)
		return () => window.removeEventListener('keydown', scrollToNextEntry)
	},[isFilmComment, scrollIndex, setScrollIndex])

	useEffect(() => {
		if (scrollIndex !== null && scrollIndex >= 0) {
			if (typeof window !== 'undefined') {
				const entry = document.getElementById(`entry-${scrollIndex}`)
				const filterBar = document.getElementById('filter-bar')
				const y = entry.getBoundingClientRect().top + window.pageYOffset + (filterBar.offsetHeight * -1)
				window.scrollTo({
					top: y,
					behavior: 'smooth',
				});
			}
		}
	}, [scrollIndex])

	const filteredResults = useMemo(() => {
		let entries = [ ...edges ]

		if (!filters) { return entries }

		// Filters
		const filter = FILTERS.filter.find(filter => filter.id === filters.filter).id
		switch (filter) {
			case 'everything':
				break
			case '2000s':
				entries = entries.filter(entry => entry.node.frontmatter.year > 1999)
				break
			case '1990s':
				entries = entries.filter(entry => entry.node.frontmatter.year > 1989 && entry.node.frontmatter.year < 2000)
				break
			case '1980s':
				entries = entries.filter(entry => entry.node.frontmatter.year > 1979 && entry.node.frontmatter.year < 1990)
				break
			case '1970s':
				entries = entries.filter(entry => entry.node.frontmatter.year > 1969 && entry.node.frontmatter.year < 1980)
				break
			case 'pre-1970':
				entries = entries.filter(entry => entry.node.frontmatter.year < 1970)
				break
			default:
				entries = entries.filter(entry => entry.node.frontmatter?.genres && entry.node.frontmatter.genres.includes(filter))
		}

		// Sort
		const sort = FILTERS.sort.find(filter => filter.id === filters.sort).id
		switch (sort) {
			case 'random':
				entries = shuffle(entries)
				break
			case 'release-year':
				entries = sortBy(entries, [entry => entry.node.frontmatter.year])
				break
			case 'title':
				entries = sortBy(entries, [entry => entry.node.frontmatter.title.toLowerCase()])
				break
			case 'votes':
				let bundled = [];
				// Loop through likes and log each like with its related film…
				likes.forEach(like => {
					// Check to see if we’ve already created an entry for this
					// film…
					const existingEntry = bundled.findIndex(item => item.filmId === like.filmId);
					// If there is one, update its likes count…
					if (existingEntry >= 0) {
						bundled[existingEntry].likes = like.isLiked
							? bundled[existingEntry].likes + 1
							: bundled[existingEntry].likes - 1;
					// If there isn’t, create one and log its like…
					} else {
						bundled = [
							...bundled,
							{
								filmId: like.filmId,
								likes: like.isLiked ? 1 : -1,
							},
						]
					}
				});
				bundled = sortBy(bundled, [item => item.likes]);

				entries = bundled.map(item => {
					const matchingEntry = entries.find(entry => entry.node.frontmatter.index === item.filmId);
					if (matchingEntry) {
						return { ...matchingEntry };
					}
				});
				break
			case 'post-date':
			default:
				entries = sortBy(entries, [entry => entry.node.frontmatter.date]).reverse()
		}

		// Sort Direction
		const sortDirection = FILTERS.sortDirection.find(filter => filter.id === filters.sortDirection).id
		switch (sortDirection) {
			case 'descending':
				entries = entries.reverse()
				break
			case 'ascending':
			default:
		}

		return entries
	}, [filters])

	const opengraphImage = filteredResults?.[0].node?.frontmatter?.poster

	return (
		<>
			<SEO
				image={ `https://besthorrorscenes.com/posters/${opengraphImage}` }
				title='Home'
				url={ 'https://besthorrorscenes.com' }
			/>
			{
				(filteredResults && filteredResults?.length > 0) ? filteredResults.map((edge, index) => {
					const { frontmatter, html } = edge.node
					const articleLikes = likes ? likes.filter(like => like.filmId === frontmatter.index) : []

					return (
						<Article
							comments={ comments }
							content={ html }
							director={ frontmatter.director }
							entryNumber={ frontmatter.index }
							index={ index }
							isBraveBrowser={ isBraveBrowser }
							key={ `entry-${frontmatter.index}` }
							likes={ articleLikes }
							poster={ frontmatter.poster }
							rating={ frontmatter.rating }
							setComment={ setComment }
							setLike={ setLike }
							slug={ frontmatter.path }
							standalone={ false }
							suggestedBy={ frontmatter.suggestedBy }
							supportsPush={ supportsPush }
							title={ frontmatter.title }
							url={ frontmatter.url }
							year={ frontmatter.year }
						/>
					)
				}) : (
					<div className={ style.NoResults }>
						No results.
					</div>
				)
			}
		</>
	)
}

export const query = graphql`
	query EntriesQuery {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___index] }
			limit: 1000
		) {
			edges {
				node {
					frontmatter {
						director
						index
						genres
						path
						poster
						rating
						suggestedBy
						title
						url
						year
					}
					html
				}
			}
		}
	}
`

export default IndexPage
